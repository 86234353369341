import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "assets/styles/tailwind.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Loading from "./components/loading/Loading";

import { useDispatch, useSelector } from "react-redux";
import { SET_LOADING } from "store/App/actions";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import HomeScreen from "screens/HomeScreen/HomeScreen";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes/routes";
import Layout from "layouts/Layout";
import { useStatistics } from "helpers/useStatistics";
import GoogleAnalytics from "components/Google/GoogleAnalytics";
import Page403 from "components/Errors/Page403";
import Page404 from "components/Errors/Page404";

function App() {
  useStatistics();

  const isLoading = useSelector((state) => state.app.isLoading);
  const dispatch = useDispatch();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={key} />;
      }

      return null;
    });

  useEffect(() => {
    dispatch({ type: SET_LOADING, isLoading: true });
    setTimeout(() => {
      dispatch({ type: SET_LOADING, isLoading: false });
    }, 2000);
  }, []);
  return (
    <div className="App">
      <GoogleAnalytics />
      {isLoading ? <Loading></Loading> : <></>}

      <Routes>
        <Route path="/" element={<Layout></Layout>}>
          {getRoutes(routes.filter((el) => el.role === "home"))}

          {/* Route pour la page 403 */}
          <Route path="/403" element={<Page403 />} />

          {/* Route catch-all pour la page 404 */}
          <Route path="*" element={<Page404 />} />
        </Route>

        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer style={{ fontSize: "13px" }} />
    </div>
  );
}

export default App;
